import React, { useState } from "react";
import { Button, Form, InputGroup } from "react-bootstrap";
import "./TrailsSidebar.css";
import { useDispatch } from "react-redux";
import { setTrailFilters } from "../../../../../store/slices/TrailFiltersSlice";
import { useNavigate } from "react-router-dom";

type TrailsSidebarProps = {
  currentTrailId: string;
  currentPage: string;
};

const TrailsSidebar: React.FC<TrailsSidebarProps> = ({ currentTrailId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchName, setSearchName] = useState<string>("");
  const [statusFilter, setStatusFilter] = useState<string>("");
  const [sortFilter, setSortFilter] = useState<string>("");

  const applyFilters = () => {
    dispatch(
      setTrailFilters({ name: searchName, statusFilter, sortBy: sortFilter })
    );
  };
  return (
    <div className="sidebar">
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">
          Filter By Name
        </InputGroup.Text>
        <Form.Control
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          onChange={(e) => setSearchName(e.target.value)}
          value={searchName}
        />
      </InputGroup>
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">
          Filter By Status
        </InputGroup.Text>
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value={""}>--</option>
          <option value={"active"}>Active</option>
          <option value={"pending"}>Pending</option>
          <option value={"deleted"}>Inactive</option>
        </Form.Select>
      </InputGroup>
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Text id="inputGroup-sizing-sm">
          Sort By Created At
        </InputGroup.Text>
        <Form.Select
          aria-label="Default select example"
          onChange={(e) => setSortFilter(e.target.value)}
        >
          <option value={""}>--</option>
          <option value={"CreatedAtOldToNew"}>Descending</option>
          <option value={"CreatedAtNewToOld"}>Ascending</option>
        </Form.Select>
      </InputGroup>
      <Button variant="success" onClick={() => applyFilters()}>
        Apply
      </Button>
      <div className="create-trail-btn">
        <Button
          onClick={() => navigate(`/trail/new/`)}
          className="add-trail-btn"
        >
          Add trail
        </Button>
      </div>
    </div>
  );
};

export default TrailsSidebar;
