import React, { useState } from "react";
import { Navbar, Container, Nav } from "react-bootstrap";
import logo from "../../../Assets/icon.png";
import * as _ from "lodash";
import "./Header.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { Link } from "react-router-dom";

const Header: React.FC = () => {
  const user = useSelector((state: RootState) => state.user).currentUser;

  const [currentPath] = useState<string>(
    window.location.pathname.split("/")[1]
  );

  const LogOutUser = async () => {
    console.log("Logging out...");
  };

  return (
    <div className="header" style={{ direction: "ltr" }}>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand as={Link} to="/">
            <img
              alt="Darb Admin Panel Logo"
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
            />{" "}
            Darb Admin Panel
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              {!_.isEmpty(user) && (
                <>
                  <Nav className="me-auto">
                    {" "}
                    {/* Assuming you're using a Nav component */}
                    <Link
                      to="/reviews"
                      className="nav-link"
                      style={{
                        color: currentPath === "reviews" ? "#3E5E53" : "",
                      }}
                    >
                      Reviews
                    </Link>
                  </Nav>
                  <Nav className="me-auto">
                    {" "}
                    {/* Assuming you're using a Nav component */}
                    <Link
                      to="/trails"
                      className="nav-link"
                      style={{
                        color: currentPath === "trails" ? "#3E5E53" : "",
                      }}
                    >
                      Trails
                    </Link>
                  </Nav>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
          {!_.isEmpty(user) && (
            <div className="log-out-div" onClick={LogOutUser}>
              <p className="log-out">
                <strong>Log Out</strong>
              </p>
            </div>
          )}
        </Container>
      </Navbar>
    </div>
  );
};

export default Header;
