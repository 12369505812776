import React from 'react';
import { Button, Carousel } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import "./TrailCard.css"

interface TrailCardProps {
  id: number;
  photos: string[];
  rating: number;
  status: string;
  distance: number;
  difficulty: string;
  name: string;
  changeStatus: () => void;
  onDelete: () => void;
}

const TrailCard: React.FC<TrailCardProps> = ({
  id,
  rating,
  name,
  difficulty,
  distance,
  status,
  photos,
  changeStatus,
  onDelete,
}) => {
  const navigate = useNavigate();

  return (
    <div className="col-md-4  card p-3 m-2">
      <Carousel fade>
        {photos.map((url) => (
          <Carousel.Item style={{ height: '30%' }}>
            <img
              className="img-fluid rounded-2 my-2 "
              src={url}
              alt="First slide"
              height={100}
              style={{
                width: '400px',
                height: '200px'          
              }}

            />
          </Carousel.Item>
        ))}
      </Carousel>
      <div className="trail-meta-data">
        <h4 className="trail-name">{name}</h4>
        <div className="trail-sub-data">
          <span> Rating : {rating}</span>
          <span> Distance : {distance}</span>
          <span> Status : {status}</span>
          <span> Difficulty : {difficulty}</span>
        </div>
        <div className="trail-actions">
          <Button
            variant="danger"
            onClick={onDelete}
            className="btn btn-danger trail-action-btn "
          >Delete Trail</Button>
          <Button variant="success" onClick={() => navigate(`/trail/edit/${id}`)} className='trail-action-btn' >Go to trail</Button>
          <Button variant="dark" onClick={changeStatus} className='trail-action-btn'>{status === 'active' ? 'Deactivate Trail' : 'Activate Trail'}</Button>
        </div>
      </div>

    </div>
  );
};

export default TrailCard;
